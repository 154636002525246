import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import Quotes from "../../assets/img/quotes.svg";

import GooglePic from "../../assets/img/googleFullRating.svg";
import FirmyPic from "../../assets/img/firmyFullRating.svg";


const OneReviewInner = styled.div`
  background: #F4F3F0;
  padding: 60px 0;
  margin: 70px auto;

  @media (max-width: 600px) {
    padding: 40px 0;
    margin: 50px auto;
  }

  .recenze {
    display: flex;
    align-items: stretch;
    background: #FFFFFF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 20px;
    overflow: hidden;
    max-width: 1150px;
    margin: 0 auto;
    width: calc(100% - 100px);
    position: relative;

    @media (max-width: 899px) { 
      display: block;
      width: calc(100% - 50px - 80px);
      max-width: 520px;
      padding: 40px;
    }

    @media (max-width: 500px) { 
      display: block;
      padding: 25px;
      width: calc(100% - 50px - 30px);
    }

    .left {
      width: calc(100% - 450px);
      display: flex;
      align-items: center;

      @media (max-width: 1100px) { 
        width: calc(100% - 300px);
      }

      @media (max-width: 899px) { 
        width: 100%;
      }

      .leftInner {
        max-width: 560px;
        margin: 0 auto;
        display: block;
        padding: 60px 0;
        width: calc(100% - 160px);

        @media (max-width: 1100px) { 
          width: calc(100% - 100px);
        }

        @media (max-width: 899px) { 
          padding: 0;
          width: calc(100%);
        }

        @media (max-width: 500px) { 

        }

        img {
          @media (max-width: 899px) { 
            width: 39px;
          }
        }

        .rating {
          margin-top: 60px;
          display: flex;
          width: 500px;
          align-items: center;
          margin-top: 30px;

          @media (max-width: 899px) {
            margin-top: 20px;
            margin-bottom: 30px;
          }

          @media (max-width: 540px) {
            display: block;
          }

          img {
            display: block;
            max-height: 28px;
            max-width: 200px;
            margin-right: 30px;
            width: fit-content;

            @media (max-width: 899px) { 
              width: fit-content;
              margin-right: 20px;
            }

            @media (max-width: 540px) {
              margin-top: 10px;

              max-height: none;
              max-width: none;
            }
          }
        }

        p {
          font-family: NewKansas;
          font-size: 26px;
          color: #243A58;
          line-height: 39px;
          margin-top: 22px;
          margin-bottom: 25px;

          @media (max-width: 1100px) { 
            font-size: 20px;
            line-height: 32px;  
          }

          @media (max-width: 899px) { 
            font-size: 21px;
            line-height: 33px;
            margin-top: 20px;
            margin-bottom: 35px;
          }

          @media (max-width: 500px) { 
            font-size: 19px;
            line-height: 30px;
            margin-bottom: 25px;
          }

          span {
            font-family: NewKansas-Bold;
          }
        }

        .zakaznik {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #5B5B5B;
        }
      }
    }

    .right {
      width: calc(450px);
      background: #e2e1d7;
      ${props => props.recenzeImgFixed && "background: none;"}
      ${props => props.recenzeImgFixed && "display: flex;"}
      ${props => props.recenzeImgFixed && "align-items: center;"}
      ${props => props.recenzeImgFixed && "justify-content: flex-end;"}

      @media (max-width: 1100px) {
        ${props => !props.recenzeImgFixed && "width: calc(300px);"}
      }

      @media (max-width: 899px) { 
        width: 100%;
        display: flex;
        align-items: center;
        background: none;
        ${props => props.recenzeImgFixed && "justify-content: flex-start;"}
      }

     

      span {
        display: block;

        &:first-child {
          font-family: Visuelt-Medium;
          font-size: 18px;
          color: #000000;
          line-height: 27px;
        }

        &:last-child {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #5B5B5B;
          line-height: 23px;
        }
      }

      .obrazek {
        height: 100%;
        ${props => props.recenzeImgFixed && "margin-right: 85px;"}

        @media (max-width: 1100px) {
          ${props => props.recenzeImgFixed && "margin-right: 50px;"}
        }
      }

      .obrazekSmall {
        margin-right: 20px;

        @media (max-width: 380px) {
          height: 50px !important;
          width: 50px !important;
          margin-right: 10px;
        }
      }
    }
  }

  .isMain {
    .nonstop {
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-top: 15px;
      width: fit-content;

      @media (max-width: 899px) {
        margin-left: 0;
      }

      .greenDot {
        background: #49B25D;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        animation: pulse 2s infinite;
        display: inline-block;
        vertical-align: middle;
      }

      @keyframes pulse {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.25;
        }

        100% {
          opacity: 1;
        }
      }

      span {
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #48B15C;
        margin-left: 10px;
        vertical-align: middle;
      }
    }

    h1 {
      line-height: normal !important;
      margin-bottom: 30px !important;

      .big {
        font-family: Ivar;
        font-size: 64px;
        color: #000000;
        text-align: center;
        display: block;

        @media (max-width: 899px) {
          font-size: 40px;
          text-align: left;
        }

        @media (max-width: 520px) {
          font-size: 32px;
          line-height: 44px;
        }

        @media (max-width: 420px) {
          max-width: 320px;
          font-size: 40px;
          line-height: 53px;
        }

        @media (max-width: 350px) {
          font-size: 32px;
          line-height: 44px;
        }
      }
    }

    .small {
      font-family: Visuelt-Regular;
      font-size: 22px;
      color: #808080;
      text-align: center;
      margin-top: 20px;
      display: block;
      margin-bottom: 35px !important;

      a {
        font-size: 22px;
        color: #808080;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }

        @media (max-width: 520px) {
          font-size: 16px;
        }
      }

      @media (max-width: 899px) {
        text-align: left;
        margin-top: 15px;
        margin-bottom: 25px !important;
      }

      @media (max-width: 520px) {
        font-size: 17px;
      }
    }

    p {
      max-width: 780px !important;
    }
  }
`;



const OneReview = ({
  text,
  img,
  imgSmall,
  zakaznikJmeno,
  zakaznikKdo,
  recenzeImgFixed,
}) => {

  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <OneReviewInner>
      <div className="recenze">
        <div className="left">
          <div className="leftInner">
            <img src={Quotes} alt="" />
            <p>{text}</p>

            {(!isLess899) &&
              <span className="zakaznik">{zakaznikJmeno}, {zakaznikKdo}</span>
            }

            <div className="rating">
              <img alt="Nejlépe hodnocená pohřební služba - Google" src={GooglePic} />
              <img alt="Nejlépe hodnocená pohřební služba - Firmy.cz" src={FirmyPic} />
            </div>
          </div>
        </div>
        <div className="right">
          {(isLess899) ?
            <>
              <Img className="obrazekSmall" alt={zakaznikKdo} fixed={imgSmall} />
              <div className="textik">
                <span>{zakaznikJmeno}</span>
                <span>{zakaznikKdo}</span>
              </div>
            </>
            :

            <>{(recenzeImgFixed) ?
              <Img className="obrazek" alt={zakaznikKdo} fixed={img} />
            :
              <Img className="obrazek" alt={zakaznikKdo} fluid={img} />
            }</>
          }
        </div>
      </div>
    </OneReviewInner>
  );
}

export default OneReview;


