import React from "react";
import { Link, StaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import CallButtonV2 from "./CallButtonV2";
import LinkButtonV2 from "./LinkButtonV2";
import funeral from "../../assets/img/funeral.svg";
import prepaid from "../../assets/img/prepaid.svg";
import will from "../../assets/img/will.svg";
import care from "../../assets/img/care.svg";

const NonprofitInner = styled.div`
  .wrap {
    max-width: 1280px;
    margin: 0 auto;
    background: #F4F3F0;
    border-radius: 20px;
    padding: 60px 0;
    width: calc(100% - 50px);

    @media (max-width: 600px) {
      width: calc(100% - 20px);
      padding: 40px 0;
    }

    h2 {
      font-family: NewKansas;
      font-size: 36px;
      color: #2c4668;
      text-align: center;
      line-height: 50px;
      width: calc(100% - 40px);
      margin: 0 auto;

      @media (max-width: 899px) {
        font-size: 32px;
        line-height: 40px;
      }

      @media (max-width: 430px) {
        font-size: 28px;
        line-height: 35px;
      }
    }

    .desc {
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: #394B5C;
      text-align: center;
      line-height: 28px;
      max-width: 900px;
      margin: 30px auto;
      width: calc(100% - 40px);
      margin-bottom: 50px;

      @media (max-width: 1250px) {
        max-width: 700px;
      }

      @media (max-width: 430px) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 25px;
      }
    }

    ul {
      max-width: 900px;
      width: calc(100% - 50px);
      margin: 0 auto;
      text-align: center;
      margin-top: -15px;
      margin-bottom: -15px;

      @media (max-width: 860px) {
        margin: 0 auto;
        width: calc(100% - 30px);
      }

      li {
        display: inline-block;
        list-style: none;
        vertical-align: middle;
        margin: 15px;

        img {
          @media (max-width: 860px) {
            transform: scale(0.8);
          }
        }

        @media (max-width: 860px) {
          margin: 0;
        }

        @media (max-width: 400px) {
          margin: -10px;
          transform: scale(0.85);

        }
      }
    }
  }
`;


const Nonprofit = ({}) => {
  return (
    <NonprofitInner>
      <div className="wrap">
        <h2>Vybíráme miliony pro naše neziskové partnery</h2>
        <p className="desc">Naši štědří zákazníci pravidelně přispívají na dopročinné účely v odkazech jejich závětí.</p>
        
        <StaticQuery
          query={graphql`
            query {
              nadeje: file(relativePath: { eq: "nadeje.png" }) {
                childImageSharp {
                  fixed(width: 156) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              ratolest: file(relativePath: { eq: "ratolestLogo.png" }) {
                childImageSharp {
                  fixed(width: 133) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              emoter: file(relativePath: { eq: "emoterLogo.png" }) {
                childImageSharp {
                  fixed(width: 147) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              sosVesnicky: file(relativePath: { eq: "sosVesnicky.png" }) {
                childImageSharp {
                  fixed(width: 199) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              helppes: file(relativePath: { eq: "helppesLogo.png" }) {
                childImageSharp {
                  fixed(width: 85) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              zivotdetem: file(relativePath: { eq: "zivotDetemLogoHp.png" }) {
                childImageSharp {
                  fixed(width: 71) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              pesvnouzi: file(relativePath: { eq: "pesvnouzi.png" }) {
                childImageSharp {
                  fixed(width: 174) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              paraple: file(relativePath: { eq: "parapleLogo.png" }) {
                childImageSharp {
                  fixed(width: 186) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              andelStrazny: file(relativePath: { eq: "andelStraznyLogo.png" }) {
                childImageSharp {
                  fixed(width: 114) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              nadacniFondPomoci: file(relativePath: { eq: "nadacniFondPomoci.png" }) {
                childImageSharp {
                  fixed(width: 185) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
          `}
          render={data => (
            <ul>
              <li>
                <Link to="/nadeje/">
                  <Img className="obrazek" alt="NADĚJE" fixed={data.nadeje.childImageSharp.fixed} />
                </Link>
              </li>
              <li>
                <Link to="/ratolest/">
                  <Img className="obrazek" alt="Ratolesti Brno" fixed={data.ratolest.childImageSharp.fixed} />
                </Link>
              </li>
              <li>
                <Link to="/emoter/">
                  <Img className="obrazek" alt="EMOTER" fixed={data.emoter.childImageSharp.fixed} />
                </Link>
              </li>
              <li>
                <Link to="/sosvesnicky/">
                  <Img className="obrazek" alt="SOS dětské vesničky" fixed={data.sosVesnicky.childImageSharp.fixed} />
                </Link>
              </li>
              <li>
                <Link to="/helppes/">
                  <Img className="obrazek" alt="HELPPES" fixed={data.helppes.childImageSharp.fixed} />
                </Link>
              </li>
              <li>
                <Link to="/zivotdetem/">
                  <Img className="obrazek" alt="ŽIVOT DĚTEM" fixed={data.zivotdetem.childImageSharp.fixed} />
                </Link>
              </li>
              <li>
                <Link to="/pesvnouzi/">
                  <Img className="obrazek" alt="PES V NOUZI" fixed={data.pesvnouzi.childImageSharp.fixed} />
                </Link>
              </li>
              <li>
                <Link to="https://www.paraple.cz/podporte-nas/odkaz-ze-zaveti/">
                  <Img className="obrazek" alt="" fixed={data.paraple.childImageSharp.fixed} />
                </Link>
              </li>
              <li>
                <Link to="/andelstrazny/">
                  <Img className="obrazek" alt="Anděl Strážný" fixed={data.andelStrazny.childImageSharp.fixed} />
                </Link>
              </li>
              <li>
                <Link to="/nfpomoci/">
                  <Img className="obrazek" alt="Nadační fond pomoci" fixed={data.nadacniFondPomoci.childImageSharp.fixed} />
                </Link>
              </li>
            </ul>
          )}
        />
      </div>
    </NonprofitInner>
  );
}

export default Nonprofit;


