import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import { useMediaQuery } from 'react-responsive';
import { StaticQuery, graphql, Link } from "gatsby"
import Phone from "../../assets/img/telefon.svg";
import LinkButtonV2 from "./LinkButtonV2";

const EndSupportInner = styled.div`
  background: #F4F3F0;
  border-radius: 20px;
  max-width: 1280px;
  margin: 70px auto;
  width: calc(100% - 100px);
  padding: 60px 0;

  @media (max-width: 600px) {
    width: calc(100% - 20px);
    padding: 40px 0;
    margin: 50px auto;
  }

  .wrapper {
    width: calc(100% - 100px);
    max-width: calc(1150px);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 899px) {
      display: block;
      width: calc(100% - 50px);
    }



    .left {
      max-width: 830px;
      width: calc(100% - 250px - 50px);

      @media (max-width: 899px) {
        width: calc(100%);
      }

      h2 {
        font-family: NewKansas;
        font-size: 36px;
        color: #2c4668;
        line-height: 50px;
        margin-bottom: 30px;

        @media (max-width: 899px) {
          font-size: 32px;
          line-height: 40px;
        }

        @media (max-width: 430px) {
          font-size: 28px;
          line-height: 35px;
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 18px;
        color: #394B5C;
        text-align: left;
        line-height: 28px;
        max-width: 900px;
        margin: 0 auto;
        width: calc(100%);
        margin-bottom: 40px;

        @media (max-width: 1250px) {
          max-width: 700px;
          margin-left: 0;
        }

        @media (max-width: 899px) {
          margin-bottom: 25px;
        }

        @media (max-width: 430px) {
          font-size: 16px;
          line-height: 24px;
        }

        a {
          color: #394B5C;
        }
      }
      
      .contact {
        margin-top: 30px;
        display: flex;
        align-items: center;

        @media (max-width: 350px) {
          display: block;
        }

        a {
          display: inline-flex;
          align-items: center;
          width: fit-content;
          margin-right: 30px;

          @media (max-width: 500px) {
            margin-right: 20px;
          }
          
          img {
            margin-right: 15px;

            @media (max-width: 500px) {
              margin-right: 10px;
              height: 17px;
            }
          }

          span {
            font-family: Visuelt-Bold;
            font-size: 21px;
            color: black;

            @media (max-width: 500px) {
              font-size: 16px;
            }
          }
        }

        .nonstop {
          display: inline-block;
          vertical-align: baseline;

          .greenDot {
            background: #49B25D;
            height: 10px;
            width: 10px;
            border-radius: 100%;
            animation: pulse 2s infinite;
            display: inline-block;
            vertical-align: middle;
          }

          @keyframes pulse {
            0% {
              opacity: 1;
            }

            50% {
              opacity: 0.25;
            }

            100% {
              opacity: 1;
            }
          }

          span {
            font-family: Visuelt-Medium;
            font-size: 16px;
            color: #48B15C;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
      }
    }

    .right {
      @media (max-width: 899px) {
        margin-top: 20px;
      }
    }
  }
`;



const EndSupport = ({
  customText
}) => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  const isLess500 = useMediaQuery({
    query: '(max-width: 500px)'
  });


  return (
    <EndSupportInner>
      <div className="wrapper">
        <div className="left">
          <h2>Rádi vám se vším pomůžeme</h2>
          <p>{customText ? customText : "Chápeme, že smrt blízkého je situace, kterou neplánujete. Zavolejte naší pohřební službě a jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a vytvoříme pro vás a vaše blízké to nejosobnější poslední rozloučení."}</p>
          <LinkButtonV2 to="/nabidka/dotaznik/" text="Začít plánovat pohřeb" arrow />
          <div className="contact">
            <a href="tel:00420315558136" className="phone">
              <img src={Phone} alt="" />
              <span>315 558 136</span>                
            </a>
            <div className="nonstop">
              <div className="greenDot"></div>
              <span>K dispozici 24/7</span>
            </div>
          </div>
        </div>
        <div className="right">
          <StaticQuery
            query={graphql`
              query {
                big: file(relativePath: { eq: "podpora.png" }) {
                  childImageSharp {
                    fixed(width: 250, quality: 100) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }

                small: file(relativePath: { eq: "podpora.png" }) {
                  childImageSharp {
                    fixed(width: 160, quality: 100) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            `}
            render={data => (
              <>
                {isLess500 ?
                  <Img className="obrazek" alt="Naše podpora" fixed={data.small.childImageSharp.fixed} />
                :
                  <Img className="obrazek" alt="Naše podpora" fixed={data.big.childImageSharp.fixed} />
                }
              </>
            )}
          />
        </div>
      </div>
    </EndSupportInner>
  );
}

export default EndSupport;


