import React from "react";
import { Link, StaticQuery } from "gatsby";
import styled from "styled-components";
import CallButtonV2 from "./CallButtonV2";
import LinkButtonV2 from "./LinkButtonV2";
import funeral from "../../assets/img/funeral.svg";
import prepaid from "../../assets/img/prepaid.svg";
import will from "../../assets/img/will.svg";
import care from "../../assets/img/care.svg";

const ServicesInner = styled.div`
  .wrap {
    max-width: 1280px;
    margin: 0 auto;
    background: #F4F3F0;
    border-radius: 20px;
    padding: 60px 0;
    width: calc(100% - 50px);

    @media (max-width: 600px) {
      width: calc(100% - 20px);
      padding: 40px 0;
    }

    h2 {
      font-family: NewKansas;
      font-size: 36px;
      color: #2c4668;
      text-align: center;
      line-height: 50px;
      width: calc(100% - 40px);
      margin: 0 auto;

      @media (max-width: 899px) {
        font-size: 32px;
        line-height: 40px;
      }

      @media (max-width: 430px) {
        font-size: 28px;
        line-height: 35px;
      }
    }

    .desc {
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: #394B5C;
      text-align: center;
      line-height: 28px;
      max-width: 900px;
      margin: 30px auto;
      width: calc(100% - 40px);
      margin-bottom: 50px;

      @media (max-width: 1250px) {
        max-width: 700px;
      }

      @media (max-width: 430px) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 25px;
      }
    }

    .blocks {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 1150px;
      margin: 0 auto;

      @media (max-width: 1250px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 50px;
        max-width: 700px;
        width: calc(100% - 50px);
        margin: 0 auto;
      }

      @media (max-width: 600px) {
        display: block;
      }
      
      .block {
        text-align: center;
        background: #FFFFFF;
        box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
        border-radius: 10px;
        width: 230px;
        padding: 20px;
        padding-top: 30px;
        position: relative;

        @media (max-width: 1250px) {
          width: calc(100% - 40px);
        }

        @media (max-width: 600px) {
          margin-top: 20px;
        }

        img {
          margin-bottom: 15px;
        }

        h3 {
          margin-bottom: 15px;
          font-family: Visuelt-Medium;
          font-size: 20px;
          color: #2E4963;
          text-align: center;

          a {
            color: #2E4963;
            text-decoration: none;
            font-family: Visuelt-Medium;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 15px;
          color: #44596D;
          text-align: center;
          line-height: 21px;

          a {
            color: #44596D;
          }
        }

        a {
          width: 100%;
          padding-left: 0;
          padding-right: 0;
          margin-top: 25px;
          
          @media (max-width: 600px) {
            margin: 0 auto;
            margin-top: 25px;
          }
        }
      }
    }
  }
`;


const Services = ({}) => {
  return (
    <ServicesInner>
      <div className="wrap">
        <h2>Jak vám můžeme pomoci?</h2>
        <p className="desc">{"Jsme vaším přátelským průvodcem při řešení všech výzev spojených s úmrtím. Od úplného začátku a plánování, po vypravení pohřbu a asistence vaší rodině. Budeme vám po celou dobu oporou."}</p>
        <div className="blocks">
          <div className="block">
            <img src={funeral} alt="Pohřební služba" />
            <h3><Link to="/pohrebni-sluzba/">Pohřební služba</Link></h3>
            <p>Jedinečné poslední rozloučení s vaším blízkým po celé České republice. Empatické jednání, transparentní ceny od 14 990 Kč.</p>
            <LinkButtonV2 to="/nabidka/dotaznik/" text="Začít plánovat pohřeb" light />
          </div>
          <div className="block">
            <img src={prepaid} alt="Předplacený pohřeb" />
            <h3><Link to="/predplaceny-pohreb/">Předplacený pohřeb</Link></h3>
            <p>Předobjednejte si vlastní pohřeb. Ušetřete vaším blízkým stres a veškeré výdaje spojené s jeho organizací. Již od 990 Kč měsíčně.</p>
            <LinkButtonV2 to="/predplaceny-pohreb/" text="Předplatit pohřeb" light />
          </div>
          <div className="block">
            <img src={will} alt="Online závěť" />
            <h3><Link to="/zaveti/">Online závěť</Link></h3>
            <p>Pomůžeme vám sepsat vlastní závěť za 15 minut z pohodlí domova. Sestaveno advokáty. Neomezené úpravy.</p>
            <LinkButtonV2 to="/zaveti/" text="Vyzkoušet zdarma" light />
          </div>
          <div className="block">
            <img src={care} alt="Goodbye Care" />
            <h3><Link to="/care/">Goodbye Care</Link></h3>
            <p>Aplikace a 24/7 asistenční služba, která vám a vaší rodině pomůže se zorientovat a vyřešit všechny úkoly ohledně řešení úmrtí.</p>
            <LinkButtonV2 to="/care/" text="Zjistit více" light />
          </div>
        </div>
      </div>
    </ServicesInner>
  );
}

export default Services;


