import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import MapaPokryti from "../../assets/img/mapaPokryti.svg";
import Pin from "../../assets/img/pin.svg";

const RegionsV2Inner = styled.div`
  max-width: 1150px;
  width: calc(100% - 100px);
  margin: 70px auto;

  @media (max-width: 899px) {
    width: calc(100% - 50px);
    max-width: 700px;
  }

  @media (max-width: 600px) {
    margin: 50px auto;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 710px) {
      display: block;
    }

    .left {
      width: 500px;

      @media (max-width: 1060px) {
        width: 400px;
      }

      @media (max-width: 780px) {
        width: 350px;
      }

      @media (max-width: 710px) {
        width: 100%;
      }

      h2 {
        font-family: NewKansas;
        font-size: 36px;
        color: #2c4668;
        text-align: left;
        line-height: 50px;
        margin-bottom: 30px;

        @media (max-width: 899px) {
          font-size: 32px;
          line-height: 40px;
        }

        @media (max-width: 430px) {
          font-size: 28px;
          line-height: 35px;
        }
      }

      .desc {
        font-family: Visuelt-Regular;
        font-size: 16px;
        text-align: left;
        color: #394B5C;
        line-height: 25px;

        @media (max-width: 430px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      h3 {
        font-family: Visuelt-Medium;
        font-size: 18px;
        color: #000000;
        line-height: 25px;
        margin-top: 40px;
      }
    }
    
    a {
      width: calc(100% - 550px);
      background: #F4F3F0;
      border-radius: 10px;

      @media (max-width: 1060px) {
        width: calc(100% - 430px);
      }

      @media (max-width: 780px) {
        width: calc(100% - 380px);
      }

      @media (max-width: 710px) {
        display: block;
        width: 100%;
        margin: auto;
        margin-top: 30px;
      }

      &:hover {
        background: #E2DFD8;
      }

      img {
        width: 370px;
        display: block;
        margin: 0 auto;
        padding: 30px 0;

        @media (max-width: 899px) {
          width: 230px;
        }
      }
    }
  }

  .bot {
    margin-top: 40px;

    ul {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 25px;
      grid-row-gap: 25px;

      @media (max-width: 1232px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (max-width: 1000px) {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 18px;
        grid-row-gap: 18px;
      }

      @media (max-width: 680px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
      }

      

      a {
        text-decoration: none;
        grid-auto-rows: 1fr;

      }

      li {
        background: #F4F3F0;
        padding: 11px 16px;
        border-radius: 3px;
        list-style: none;
        display: flex;
        align-items: center;
        height: calc(100% - 22px);

       
        &:hover {
          background: #E2DFD8;
        }

        img {
          margin-right: 12px;
          height: 18px;
        }

        span {
          font-family: Visuelt-Regular;
          font-size: 17px;
          color: #132C1C;
          margin-bottom: -1.5px;

          @media (max-width: 1000px) {
            font-size: 16px;
          }
        }
      }
    }
  }


  ${props => props.isMainPage && `
    max-width: 1280px;
    margin: 0 auto;
    background: #F4F3F0;
    border-radius: 20px;
    padding: 60px 0;
    width: calc(100% - 50px);

    @media (max-width: 600px) {
      width: calc(100% - 20px);
      padding: 40px 0;
    }

    .wrapper {
      max-width: 1150px;
      margin: 0 auto;
      width: calc(100% - 50px);

      .top {
        a {
          background: #eae8e2;

          &:hover {
            background: #e1ddd1;
          }
        }
      }

      .bot {
        li {
          background: #eae8e2;

          &:hover {
            background: #e1ddd1;
          }
        }
      }
    }
  `}
`;



const RegionsV2 = ({isMainPage}) => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <RegionsV2Inner isMainPage={isMainPage}>
      <div className="wrapper">
        <div className="top">
          <div className="left">
            <h2>Fungujeme po celé ČR</h2>
            <p className="desc">Naše pohřební služba funguje ve většině měst v České republice. V případě zájmu jsme schopni dojet kamkoliv za poplatek navíc. Zbytek služeb funguje 100% online. Neváhejte se na nás prosím obrátit.</p>
            <h3>Regiony naší pohřební služby:</h3>
          </div>
          <Link to="/pobocky/">
            <img src={MapaPokryti} alt="Mapa pokrytí" />
          </Link>
        </div>
        <div className="bot">
          <ul>
            <Link to="/pohrebni-sluzba-praha/">
              <li>
                <img src={Pin} alt="" />
                <span>Praha a okolí</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-brno/">
              <li>
                <img src={Pin} alt="" />
                <span>Brno a okolí</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-ostrava/">
              <li>
                <img src={Pin} alt="" />
                <span>Ostrava a okolí</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-plzen/">
              <li>
                <img src={Pin} alt="" />
                <span>Plzeň a okolí</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-liberec/">
              <li>
                <img src={Pin} alt="" />
                <span>Liberec</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-ceske-budejovice/">
              <li>
                <img src={Pin} alt="" />
                <span>České Budějovice</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-usti-nad-labem/">
              <li>
                <img src={Pin} alt="" />
                <span>Ústí nad Labem</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-hradec-kralove/">
              <li>
                <img src={Pin} alt="" />
                <span>Hradec Králové</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-pardubice/">
              <li>
                <img src={Pin} alt="" />
                <span>Pardubice</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-havirov/">
              <li>
                <img src={Pin} alt="" />
                <span>Havířov</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-kladno/">
              <li>
                <img src={Pin} alt="" />
                <span>Kladno</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-most/">
              <li>
                <img src={Pin} alt="" />
                <span>Most</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-opava/">
              <li>
                <img src={Pin} alt="" />
                <span>Opava</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-jihlava/">
              <li>
                <img src={Pin} alt="" />
                <span>Jihlava</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-teplice/">
              <li>
                <img src={Pin} alt="" />
                <span>Teplice</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-decin/">
              <li>
                <img src={Pin} alt="" />
                <span>Děčín</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-chomutov/">
              <li>
                <img src={Pin} alt="" />
                <span>Chomutov</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-jablonec-nad-nisou/">
              <li>
                <img src={Pin} alt="" />
                <span>Jablonec nad Nisou</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-kolin/">
              <li>
                <img src={Pin} alt="" />
                <span>Kolín</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-pisek/">
              <li>
                <img src={Pin} alt="" />
                <span>Písek</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-uherske-hradiste/">
              <li>
                <img src={Pin} alt="" />
                <span>Uherské Hradiště</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-litomerice/">
              <li>
                <img src={Pin} alt="" />
                <span>Litoměřice</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-kutna-hora/">
              <li>
                <img src={Pin} alt="" />
                <span>Kutná Hora</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-ricany/">
              <li>
                <img src={Pin} alt="" />
                <span>Říčany</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-podebrady/">
              <li>
                <img src={Pin} alt="" />
                <span>Poděbrady</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-cesky-krumlov/">
              <li>
                <img src={Pin} alt="" />
                <span>Český Krumlov</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-caslav/">
              <li>
                <img src={Pin} alt="" />
                <span>Čáslav</span>
              </li>
            </Link>

            <Link to="/pohrebni-sluzba-sazava/">
              <li>
                <img src={Pin} alt="" />
                <span>Sázava</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </RegionsV2Inner>
  );
}

export default RegionsV2;


