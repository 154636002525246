import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';

import CallButtonV2 from "./CallButtonV2";


const FeaturesInner = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;

  @media (max-width: 600px) {
    margin: 50px auto;
  }

  .vCemJsmeJini {
    margin: 0 auto;  
    width: calc(100% - 100px);

    @media (max-width: 899px) {
      width: calc(100% - 50px);
    }

    h2 {
      text-align: center;
      font-family: NewKansas;
      font-size: 36px;
      color: #2c4668;
      line-height: 50px;

      @media (max-width: 899px) {
        font-size: 32px;
        line-height: 40px;
      }

      @media (max-width: 430px) {
        font-size: 28px;
        line-height: 35px;
      }
    }

    .desc {
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: #394B5C;
      text-align: center;
      line-height: 28px;
      max-width: 900px;
      margin: 30px auto;
      width: calc(100%);

      @media (max-width: 1250px) {
        max-width: 700px;
      }

      @media (max-width: 430px) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 25px;
      }
    }
    
    .features {
      margin-top: 50px;

      @media (min-width: 900px) {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        max-width: 1150px;
      }

      .feature {
        margin: 0 auto;
        margin-top: 60px;
        max-width: 600px;

        &:first-child {
          @media (max-width: 900px) {
          }
        }

        @media (min-width: 900px) {
          margin: 0;
          max-width: 340px;
          width: calc((100% - 50px)/3);
        }

        img {
          margin: 0 auto;
          display: block;
          margin-bottom: 20px;

          @media (min-width: 900px) {
            margin-bottom: 25px;
          }
        }

        h3 {
          font-family: Visuelt-Bold;
          font-size: 20px;
          color: #000000;
          text-align: center;
          margin-bottom: 15px;
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #585858;
          text-align: center;
          line-height: 25px;

          a {
            color: #585858;
          }
        }
      }
    }

    a {
      margin: 0 auto;
      margin-top: 30px;
      display: block;

      @media (max-width: 360px) {
        width: calc(100% - 50px);
      }

      img {
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
      }
    }

    p {
      a {
        margin: 0;
        display: inline-block;
      }
    }
  }
  
`;



const FeaturesV2 = ({
  customH2,
  heading1,
  heading2,
  heading3,
  text1,
  text2,
  text3,
  desc,
  ilustrace1,
  ilustrace2,
  ilustrace3,
  callButton
}) => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <FeaturesInner>
      <section className="vCemJsmeJini">
        <h2>{customH2}</h2>
        {desc && <p className="desc">{desc}</p>}
        <div className="features">
          <div className="feature">
            <img src={ilustrace1} alt={heading1} />
            <h3>{heading1}</h3>
            <p>{text1}</p>
          </div>
          <div className="feature">
            <img src={ilustrace2} alt={heading2} />
            <h3>{heading2}</h3>
            <p>{text2}</p>
          </div>
          <div className="feature">
            <img src={ilustrace3} alt={heading3} />
            <h3>{heading3}</h3>
            <p>{text3}</p>
          </div>
        </div>
        {callButton && <CallButtonV2 />}
      </section>
    </FeaturesInner>
  );
}

export default FeaturesV2;


